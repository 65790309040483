import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WBNB variable is wbnb (BNB,WBNB)
   */
  {
    pid: 0,
    lpSymbol: 'PIPI',
    lpAddresses: {
      5438: '0xf86e639ff387b6064607201a7a98f2c2b2feb05f',
      56: '0xf86e639ff387b6064607201a7a98f2c2b2feb05f',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'PIPI-BNB LP',
    lpAddresses: {
      5438: '',
      56: '0xb43E17b52122e1c6E81482Ca072844982C179033',
    },
    token: tokens.pipi,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'USDT-BNB LP',
    lpAddresses: {
      5438: '',
      56: '0x45d528cd746d7bb44f8028c37ccac0608fa02751',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  
    
    
  	  

]

export default farms
